import API from './api'
import { axiosWithErrorHandler } from './axios'

export const GET_FILTER_REPORT_BY_ID = async (userId: string, startDate: string, endDate: string, gameTypeChecked: string, page: number, limit: number) => {
    let urlFilterId = ""
    if(userId) {
        urlFilterId = `&username=${userId}`
    }
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_REPORT}?page=${page}&pageSize=${limit}${urlFilterId}&startDate=${startDate}&endDate=${endDate}&gameType=${gameTypeChecked}`)
    return response.data;

};

export const GET_BET_DETAIL_BY_ID = async (userId: string, startDate: string, endDate: string, gameTypeChecked: string, page: number, limit: number) => {
    let urlFilterId = ""
    if(userId) {
        urlFilterId = `&username=${userId}`
    }
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_BET_DETAIL}?page=${page}&pageSize=${limit}${urlFilterId}&startDate=${startDate}&endDate=${endDate}&gameTypes=${gameTypeChecked}`)
    return response.data;

};

export const GET_BET_DETAIL_GAME_BY_ID = async (gameId: string, betId: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_BET_DETAIL_GAME}?gameId=${gameId}&betId=${betId}`)
    return response.data;
};


export const GET_TOURNAMENT_WINLOSE = async (title: string, startDate: string, endDate: string, page: number, limit: number) => {
    let urlFilteTitle = ""
    if(title) {
        urlFilteTitle = `&title=${title}`
    }
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_TOURNAMENT_WINLOSE}?page=${page}&pageSize=${limit}${urlFilteTitle}&startDate=${startDate}&endDate=${endDate}`)
    return response.data;

}

export const GET_TOURNAMENT_REWARDS = async (tournamentId: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_TOURNAMENT_REWARD}?tournamentId=${tournamentId}`)
    return response.data;

}