import { Component, Vue } from "vue-property-decorator";
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'
import vSelect from 'vue-select';

import 'vue2-datepicker/index.css';
import 'vue-select/dist/vue-select.css';
import { GET_TOURNAMENT_WINLOSE } from "../../../../api/report"

Vue.use(VModal, { componentName: 'modal' })


@Component({
    components: {
        DatePicker,
        vSelect,
    },
    filters: {
        date(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        currency(value: any) {
            value = isNaN(value) ? 0 : value
            const val = (Math.floor(value * 100) / 100).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})

class TournamentWinLose extends Vue {
    public tournamentData: any = []
    private model: Model;

    public dateFilter = {
        startDate: moment().local().startOf('day').toDate(),
        endDate: moment().local().endOf('day').toDate(),
    }

    public tournamentTitle = ''
    public roleName = ""

    private paginate = {
        page: 1,
        limit: 50
    }

    public pageSize = 0;
    public paginateShow = false;
    public dataLoading = false;

    constructor() {
        super();
        this.model = new Model();
    }

    private loaderStyle = {
        color: '#CFA137',
        loader: 'spinner',
        width: 145,
        height: 235,
        backgroundColor: '#000000',
        opacity: 0.5,
    }
    

    handlePick(date) {
        this.dateFilter.endDate = moment(date).local().endOf('day').toDate()
    }

    async mounted() {
        this.addRouteName()
        this.dataLoading = false
        await this.getTournamentWinLose(this.tournamentTitle, this.paginate.page, this.paginate.limit)
        this.dataLoading = true
    }

    private addRouteName(): void {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public async selectDate(option: string): Promise<void> {
        switch (option) {
            case 'option1':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).subtract(1, 'days').toDate()
                break;
            case 'option2':
                this.dateFilter.startDate = moment().local().startOf('day').toDate()
                this.dateFilter.endDate = moment().local().endOf('day').toDate()
                break;
            case 'option3':
                this.dateFilter.startDate = moment().local(true).startOf('day').subtract(1, 'days').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('day').subtract(1, 'days').toDate()
                break;
            case 'option4':
                this.dateFilter.startDate = moment().local(true).startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('week').toDate()
                break;
            case 'option5':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'week').startOf('week').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'week').endOf('week').toDate()
                break;
            case 'option6':
                this.dateFilter.startDate = moment().local(true).startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).endOf('month').toDate()
                break;
            case 'option7':
                this.dateFilter.startDate = moment().local(true).subtract(1, 'months').startOf('month').toDate()
                this.dateFilter.endDate = moment().local(true).subtract(1, 'months').endOf('month').toDate()
                break;
            case 'option8':
                this.dateFilter.startDate = moment(this.dateFilter.startDate).add(1, 'days').toDate()
                this.dateFilter.endDate = moment(this.dateFilter.endDate).add(1, 'days').toDate()
                break;
            default:
                break;
        }
        await this.getTournamentWinLose(this.tournamentTitle, this.paginate.page, this.paginate.limit)
    }

    public async filterWinLoseData(): Promise<void> {
        await this.getTournamentWinLose(this.tournamentTitle, this.paginate.page, this.paginate.limit)
    }

    public goBack(id: string, role: string): void {
        this.dataLoading = false
        this.getTournamentWinLose(this.tournamentTitle, this.paginate.page, this.paginate.limit)
        this.dataLoading = true
    }

    private async getTournamentWinLose( tournamentTitle: string, page: number, limit: number): Promise<void> {
        this.tournamentData = []
        const loader = this.$loading.show( this.loaderStyle );
        
            
        const list = await GET_TOURNAMENT_WINLOSE(tournamentTitle, (this.dateFilter.startDate).toISOString(), (this.dateFilter.endDate).toISOString(), page, limit)
        this.tournamentData = list.data;

        if (this.tournamentData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.tournamentData.metadata.length; i++, k++) {
                this.tournamentData.metadata[i].no = k - this.paginate.limit
            }
            this.paginateShow = true
            this.pageSize = this.tournamentData.pageInfo[0].size;
        } else {
            this.tournamentData = []
            this.paginateShow = false
        }

        loader.hide()
    }

}

export default TournamentWinLose