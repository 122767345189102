










































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import TournamentWinLoseComponent from "./TournamentWinLose.component";

@Component
export default class TournamentWinLose extends Mixins(TournamentWinLoseComponent) { }
